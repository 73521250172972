import React, { useReducer } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';

import './App.scss';
import { StoreProvider, appReducer, initialState } from "./Store"
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Auth/Login'));
const Logout = React.lazy(() => import('./views/Auth/Logout'));
const CheckAuth = React.lazy(() => import('./views/Auth/CheckAuth'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

axios.defaults.withCredentials = true
function App() {
  const [appState, appDispatch] = useReducer(appReducer, initialState)
  const isLogged = (requiredPermissions) => {
    const stateUser = appState.AuthState
    if (stateUser && stateUser.isAuth) {
      return true;
    }
    return false
  }

  const PrivateRoute = ({ component: Component, requiredPermissions, onLogout, ...rest }) => (
    <Route {...rest} render={(props) => {
      return isLogged(requiredPermissions) === true
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/CheckAuth',
          state: { from: props.location }
        }} />
    }

    } />
  )
  return (
    <StoreProvider appState={appState} appDispatch={appDispatch}>
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/CheckAuth" name="Checking Authorization..." render={props => <CheckAuth {...props} />} />
            <Route exact path="/logout" name="Logout..." render={props => <Logout {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <PrivateRoute path="/" name="Home" requiredPermissions={[]} component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </StoreProvider>
  );
}

export default App;
