import React, { createContext, useContext } from 'react';

export const initialState = {}

export const appReducer = (state, action) => {
    return { ...state, ...action }
}

export const StateContext = createContext();

export const StoreProvider = ({ appState, appDispatch, children }) => (
    <StateContext.Provider
        value={{ appState, appDispatch }}
        children={children}
    />
);

export const useStore = () => useContext(StateContext);